define("discourse/plugins/discourse-groups-review-queue/discourse/connectors/after-reviewable-post-user/review-queue-show-groups", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReviewQueueShowGroups extends _component.default {
    get groupNames() {
      return this.args.outletArgs.user.groups?.join(', ');
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="review-queue-groups">Groups: {{ this.groupNames}}</div>
      
    */
    {
      "id": "ImDryEBg",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"review-queue-groups\"],[12],[1,\"Groups: \"],[1,[30,0,[\"groupNames\"]]],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-groups-review-queue/discourse/connectors/after-reviewable-post-user/review-queue-show-groups.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ReviewQueueShowGroups;
});